







































































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CompanyLogoComponent from '@/components/cards/CompanyLogoComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { Data } from '@/utils/types/WidgetData';

@Component({
  components: { CompanyLogoComponent, ButtonComponent },
  inheritAttrs: false,
})
export default class LogoListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'LogoListWidgetStore';

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: '' })
  private readonly filter!: string;

  @Prop({ required: false, default: '' })
  private readonly type!: string;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  created(): void {
    this.setDataConfig();
  }

  private navigateTo(url: string): string | null {
    if (url) {
      let r = url;
      const matches = url.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return null;
  }
}
